<template>
  <notification-item :message="message" @delete-item="deleteItem" @close="close">
    <template v-slot:title>
      <span>{{ $t('_.title') }}</span>
    </template>
    <p class="main-msg">{{ $t('_.statusMessage', { teamName, captain: captainNickname }) }}</p>
  </notification-item>
</template>

<script>
import Specific from '@shared/types/Specific';
import NotificationItem from '@shared/components/notification/NotificationItem.vue';

export default {
  name: 'TeamHandoverConfirmItem',
  components: { NotificationItem },
  lexicon: 'shared.notification.TEAM_CAPTAIN_HANDOVER_CONFIRM',
  props: {
    message: Specific,
  },
  computed: {
    team() {
      return this.message?.data?.team;
    },
    user() {
      return this.message?.data?.user;
    },
    teamName() {
      return this.team.name;
    },
    captainNickname() {
      return this.user.captainNickname;
    },
  },
  methods: {
    deleteItem(notificationId) {
      this.$emit('delete-item', notificationId);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
</style>
